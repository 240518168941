<template>
  <div class="trucks-container" :class="{ show: isOpenSecondFilter }">
    <section>
      <div class="home-sub-title text-center">
        <p>5501 Adams ST Unit C Matteson, IL 60443</p>
      </div>
      <div class="home-welcome text-center">
        <h2>TRUCKS</h2>
      </div>
    </section>
    <section class="my-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="trucks-left col-md-3 mb-sm-3">
            <div
              class="second-left-filter filter-container mt-4 px-2"
              :class="{ show: isOpenSecondFilter }"
            >
              <h5 class="fw-bold">TRUCKS FOR SALE</h5>
              <p class="mb-2">
                {{ allTrucksLength ? 1 : 0 }} - {{ allTrucksLength }} OF
                {{ allTrucksLength }} LISTINGS
              </p>
              <div class="filter-wrapper">
                <div class="applied-filters p-2">
                  <div class="d-block d-sm-none title my-2">
                    <button
                      @click="isOpenSecondFilter = false"
                      class="w-100 h-100 p-2"
                    >
                      DONE
                    </button>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <h6 class="fw-bold">Applied Filters</h6>
                    <span
                      v-if="allSelected.length"
                      @click="clearAll"
                      class="text-decoration-underline"
                      >Clear All</span
                    >
                  </div>
                  <div class="d-flex gap-1 flex-wrap">
                    <div
                      v-for="item in allSelected2"
                      :key="item"
                      class="filters"
                    >
                      <fa
                        @click="deleteFilter(item)"
                        class="fa text-light"
                        :icon="['fas', 'xmark']"
                      />
                      {{ item }}
                    </div>
                  </div>
                </div>
                <div class="search-wrap">
                  <div class="header-wrap p-1 px-2">
                    <h6>Quick Search</h6>
                  </div>
                  <div class="search-input d-flex gap-2">
                    <input
                      v-model.lazy="search"
                      type="text"
                      placeholder="Enter keyword(s)"
                    />
                    <button>Search</button>
                  </div>
                </div>
                <base-accordion
                  title="Listing Type and Auction Results"
                  :options="allFilters?.listing_type_options"
                  :opened="true"
                  :isAvailable="filter.listingType"
                  @input="getListingType"
                ></base-accordion>
                <base-accordion
                  title="Manufacturer"
                  :options="allFilters?.manufacturer_options"
                  :opened="true"
                  :isAvailable="filter.manufacturer"
                  @input="getManufacturer"
                ></base-accordion>
                <search-accordion
                  title="Year"
                  :opened="true"
                  :isAvailable="filter.year"
                  placeholder1="Min"
                  placeholder2="Max"
                  @input="getYear"
                ></search-accordion>
                <search-accordion
                  title="Price"
                  :opened="true"
                  :isAvailable="filter.price"
                  placeholder1="$ Min"
                  placeholder2="$ Max"
                  @input="getPrice"
                ></search-accordion>
                <base-accordion
                  title="Condition"
                  :options="allFilters?.condition_options"
                  :isAvailable="filter.condition"
                  @input="getCondition"
                ></base-accordion>
                <base-accordion
                  title="State"
                  :options="allFilters?.state_options"
                  :isAvailable="filter.state"
                  @input="getState"
                ></base-accordion>
                <base-accordion
                  title="City"
                  :options="allFilters?.city_options"
                  :isAvailable="filter.city"
                  @input="getCity"
                ></base-accordion>
                <base-accordion
                  title="Country"
                  :options="allFilters?.country_options"
                  :isAvailable="filter.country"
                  @input="getCountry"
                ></base-accordion>
                <base-accordion
                  title="Category"
                  :options="allFilters?.category_options"
                  :isAvailable="filter.category"
                  @input="getCategory"
                ></base-accordion>
                <div class="search-wrap">
                  <div
                    @click="isDateOpen = !isDateOpen"
                    class="header-wrap px-2"
                  >
                    <h6 class="py-1">Select a Date Range:</h6>
                  </div>
                  <template v-if="isDateOpen">
                    <div class="search-input">
                      <div class="w-100 d-flex justify-content-between gap-1">
                        <div class="position-relative">
                          <datepicker
                            style="width: 90%"
                            class="p-0"
                            v-model="filter.date.min"
                            :clearable="true"
                          />
                          <fa class="mini" :icon="['fas', 'calendar-alt']" />
                        </div>
                        <div class="position-relative">
                          <datepicker
                            style="width: 90%"
                            class="p-0"
                            v-model="filter.date.max"
                            :upper-limit="upperLimit"
                            :disabled="filter.lastDays ? true : false"
                            :clearable="true"
                          />
                          <fa class="mini" :icon="['fas', 'calendar-alt']" />
                        </div>
                      </div>
                    </div>
                    <span class="px-2 fw-bold">Or Enter Number of Days:</span>
                    <div class="mt-2 d-flex align-items-center gap-2 px-2">
                      <p>Show listings in the last</p>
                      <input
                        style="width: 3rem"
                        class="p-0"
                        v-model="filter.lastDays"
                        type="number"
                      />
                      <p>days</p>
                    </div>
                  </template>
                </div>
                <div
                  class="header-wrap text-secondary text-center mt-2 p-1 px-2"
                >
                  <h6>Additional Filters</h6>
                </div>
                <base-accordion
                  title="Drive"
                  :options="allFilters?.drive_options"
                  :isAvailable="filter.drive"
                  @input="getDrive"
                ></base-accordion>
                <search-accordion
                  title="Horsepower"
                  :isAvailable="filter.horsePower"
                  @input="getHorsePower"
                  placeholder1="Min (hp)"
                  placeholder2="Max (hp)"
                ></search-accordion>
                <search-accordion
                  :search="true"
                  title="Serial #"
                  :isInput="filter.serial"
                  @input="getSerial"
                  placeholder1="Enter Serial #"
                ></search-accordion>
                <search-accordion
                  title="Milage"
                  :isAvailable="filter.milage"
                  @input="getMilage"
                  placeholder1="Min (mi)"
                  placeholder2="Max (mi)"
                ></search-accordion>
                <search-accordion
                  title="Gross Vehicle Weight"
                  :isAvailable="filter.gvw"
                  @input="getGvw"
                  placeholder1="Min (lb)"
                  placeholder2="Max (lb)"
                ></search-accordion>
                <base-accordion
                  title="Fuel Type"
                  :options="allFilters?.fuel_type_options"
                  :isAvailable="filter.fuel"
                  @input="getFuel"
                ></base-accordion>
                <base-accordion
                  title="Cab"
                  :options="allFilters?.cab_options"
                  :isAvailable="filter.cab"
                  @input="getCab"
                ></base-accordion>
                <base-accordion
                  title="Transmission"
                  :options="allFilters?.transmission_options"
                  :isAvailable="filter.transmission"
                  @input="getTransmission"
                ></base-accordion>
                <search-accordion
                  title="Sleeper Size"
                  :isAvailable="filter.sleeper"
                  @input="getSleeper"
                  placeholder1="Min (in)"
                  placeholder2="Max (in)"
                ></search-accordion>
                <base-accordion
                  title="Gross Vehicle Weight Rating"
                  :options="allFilters?.gvwr_options"
                  :isAvailable="filter.gvwr"
                  @input="getGvwr"
                ></base-accordion>
                <base-accordion
                  title="Engine Manufacturer"
                  :options="allFilters?.engine_manufacturer_options"
                  :isAvailable="filter.engineManufacturer"
                  @input="getEngineManufacturer"
                ></base-accordion>
                <base-accordion
                  title="Number of Speeds"
                  :options="allFilters?.number_of_speeds"
                  :isAvailable="filter.numberOfSpeed"
                  @input="getNumberOfSpeed"
                ></base-accordion>
                <base-accordion
                  title="Suspension"
                  :options="allFilters?.suspension"
                  :isAvailable="filter.suspension"
                  @input="getSuspension"
                ></base-accordion>
                <base-accordion
                  title="Transmission Type"
                  :options="allFilters?.transmission_type"
                  :isAvailable="filter.transmissionType"
                  @input="getTransmissionType"
                ></base-accordion>
                <search-accordion
                  :search="true"
                  :isInput="filter.stock"
                  @input="getStock"
                  title="Stock #"
                  placeholder1="Enter Stock #"
                ></search-accordion>
              </div>
            </div>
          </div>
          <div class="trucks-right col-md-6">
            <all-trucks :search="search" @sortTruck="triggerSort"></all-trucks>
          </div>
        </div>
      </div>
    </section>
    <div class="w-100 second-footer p-3 d-sm-none d-block">
      <div @click="isOpenSort = !isOpenSort" class="w-50 text-center pointer">
        <div>
          <fa class="fa" :icon="['fas', 'arrow-down-wide-short']" />
        </div>
        <p>Sort</p>
      </div>
      <div
        @click="isOpenSecondFilter = !isOpenSecondFilter"
        class="circle-filter text-center pointer"
      >
        <fa class="fa filter-icon" :icon="['fas', 'filter']" />
        <fa class="fa" :icon="['fas', 'sort']" />
        <p>FILTER</p>
      </div>
    </div>
    <!-- mobile sort filter -->
    <div v-if="isOpenSort" class="mobile-sort">
      <p v-for="opt in allSorts" :key="opt" @click="getSort(opt.id)">
        {{ opt.name }}
      </p>
    </div>
    <base-footer></base-footer>
  </div>
</template>

<script>
import customAxios from "../api";
import BaseAccordion from "../components/UI/BaseAccordion.vue";
import SearchAccordion from "../components/UI/SearchAccordion.vue";
import AllTrucks from "../components/trucks/AllTrucks.vue";
import Datepicker from "vue3-datepicker";

const DIFF = "^^";
export default {
  components: {
    BaseAccordion,
    SearchAccordion,
    AllTrucks,
    Datepicker,
  },
  data() {
    return {
      isOpenSort: false,
      isOpenSecondFilter: false,
      search: "",
      filter: {
        listingType: "",
        category: "",
        manufacturer: "",
        condition: "",
        state: "",
        city: "",
        country: "",
        drive: "",
        fuel: "",
        cab: "",
        transmission: "",
        gvwr: "",
        engineManufacturer: "",
        numberOfSpeed: "",
        suspension: "",
        transmissionType: "",
        stock: "",
        year: {
          min: "",
          max: "",
        },
        price: {
          min: "",
          max: "",
        },
        horsePower: {
          min: "",
          max: "",
        },
        serial: "",
        milage: {
          min: "",
          max: "",
        },
        gvw: {
          min: "",
          max: "",
        },
        sleeper: {
          min: "",
          max: "",
        },
        date: {
          min: null,
          max: null,
        },
        lastDays: "",
      },
      isDateOpen: false,
      upperLimit: null,
      allFilters: {},
      allSelected: [],
      differentFilter: [
        "year",
        "price",
        "horsePower",
        "milage",
        "gvw",
        "sleeper",
      ],
      inputFilter: ["serial", "stock"],
      allSorts: [
        { name: "By Default", id: "default" },
        { name: "Price: Low to High", id: "price" },
        { name: "Price: High to Low", id: "-price" },
        { name: "Year: Low to High", id: "year" },
        { name: "Year: High to Low", id: "-year" },
        { name: "Manufacturer", id: "manufacturer" },
        { name: "Model", id: "model" },
        { name: "Stock Number", id: "stock_number" },
        { name: "Recently Added", id: "-created_at" },
        { name: "Recently Updated", id: "-updated_at" },
      ],
    };
  },
  computed: {
    allTrucksLength() {
      return this.$store.getters.allTrucks?.length;
    },
    allSelected2() {
      return this.allSelected.map((item) => {
        return item.replace("^^", "");
      });
    },
    selectedCategories() {
      return this.filter.category.split("^^").filter(Boolean);
    },
  },
  methods: {
    getSort(val) {
      this.isOpenSort = false;
      this.$store.commit("addSortTruck", val);
    },
    triggerSort() {
      this.$store.dispatch("postfilterTrucks", this.filter);
    },
    clearAll() {
      this.allSelected.length = 0;
      let arr = Object.keys(this.filter);
      arr.forEach((item) => {
        if (item == "date") {
          this.filter[item].min = null;
          this.filter[item].max = null;
          return;
        }
        if (this.differentFilter.includes(item)) {
          this.filter[item].min = "";
          this.filter[item].max = "";
          return;
        }
        this.filter[item] = "";
      });
    },
    deleteFilter(val) {
      if (this.selectedCategories.includes(val)) {
        this.filter.category = this.filter.category.replace(val + "^^", "");
        this.allSelected = this.allSelected.filter(
          (item) => item != val + "^^"
        );
        return;
      }

      if (val == "date range") {
        this.allSelected = this.allSelected.filter((item) => item != val);
        this.filter.date.min = null;
        this.filter.date.max = null;
        this.filter.lastDays = "";
        return;
      }
      if (this.differentFilter.includes(val)) {
        this.allSelected = this.allSelected.filter((item) => item != val);
        if (this.filter[val]) {
          this.filter[val].min = "";
          this.filter[val].max = "";
        }
        return;
      }
      if (this.inputFilter.includes(val)) {
        this.allSelected = this.allSelected.filter((item) => item != val);
        if (this.filter[val]) {
          this.filter[val] = "";
        }
        return;
      }
      this.allSelected = this.allSelected.filter((item) => item != val + DIFF);
      let arr = Object.keys(this.filter);
      arr.forEach((item) => {
        if (
          this.filter[item].length &&
          this.filter[item].includes(val + DIFF)
        ) {
          let newStr = this.filter[item].replace(val + DIFF, "");
          this.filter[item] = newStr;
        }
      });
    },
    setFilterMM(val) {
      this.filter[val.name].min = val.min;
      this.filter[val.name].max = val.max;
    },
    setInputFilter(val) {
      return this.allSelected.indexOf(val);
    },
    getSerial(val) {
      this.filter.serial = val;
      if (this.setInputFilter("serial") == -1) this.allSelected.push("serial");
    },
    getStock(val) {
      this.filter.stock = val;
      if (this.setInputFilter("stock") == -1) this.allSelected.push("stock");
    },
    getSleeper(val) {
      this.setFilterMM({ name: "sleeper", min: val.min, max: val.max });
      if (this.setInputFilter("sleeper") == -1)
        this.allSelected.push("sleeper");
    },
    getGvw(val) {
      this.setFilterMM({ name: "gvw", min: val.min, max: val.max });
      if (this.setInputFilter("gvw") == -1) this.allSelected.push("gvw");
    },
    getMilage(val) {
      this.setFilterMM({ name: "milage", min: val.min, max: val.max });
      if (this.setInputFilter("milage") == -1) this.allSelected.push("milage");
    },
    getHorsePower(val) {
      this.setFilterMM({ name: "horsePower", min: val.min, max: val.max });
      if (this.setInputFilter("horsePower") == -1)
        this.allSelected.push("horsePower");
    },
    getPrice(val) {
      this.setFilterMM({ name: "price", min: val.min, max: val.max });
      if (this.setInputFilter("price") == -1) this.allSelected.push("price");
    },
    getYear(val) {
      this.setFilterMM({ name: "year", min: val.min, max: val.max });
      if (this.setInputFilter("year") == -1) this.allSelected.push("year");
    },
    getTransmissionType(val) {
      if (this.getAvailability(val.transmission_type)) {
        this.deleteFilter(val.transmission_type);
      } else {
        this.filter.transmissionType += val.transmission_type + DIFF;
        this.allSelected.push(val.transmission_type + DIFF);
      }
    },
    getSuspension(val) {
      if (this.getAvailability(val.suspension)) {
        this.deleteFilter(val.suspension);
      } else {
        this.filter.suspension += val.suspension + DIFF;
        this.allSelected.push(val.suspension + DIFF);
      }
    },
    getNumberOfSpeed(val) {
      if (this.getAvailability(val.number_of_speeds)) {
        this.deleteFilter(val.number_of_speeds);
      } else {
        this.filter.numberOfSpeed += val.number_of_speeds + DIFF;
        this.allSelected.push(val.number_of_speeds + DIFF);
      }
    },
    getEngineManufacturer(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.engineManufacturer += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getGvwr(val) {
      if (this.getAvailability(val.gvwr)) {
        this.deleteFilter(val.gvwr);
      } else {
        this.filter.gvwr += val.gvwr + DIFF;
        this.allSelected.push(val.gvwr + DIFF);
      }
    },
    getTransmission(val) {
      if (this.getAvailability(val.transmission)) {
        this.deleteFilter(val.transmission);
      } else {
        this.filter.transmission += val.transmission + DIFF;
        this.allSelected.push(val.transmission + DIFF);
      }
    },
    getCab(val) {
      if (this.getAvailability(val.cab)) {
        this.deleteFilter(val.cab);
      } else {
        this.filter.cab += val.cab + DIFF;
        this.allSelected.push(val.cab + DIFF);
      }
    },
    getFuel(val) {
      if (this.getAvailability(val.fuel_type)) {
        this.deleteFilter(val.fuel_type);
      } else {
        this.filter.fuel += val.fuel_type + DIFF;
        this.allSelected.push(val.fuel_type + DIFF);
      }
    },
    getDrive(val) {
      if (this.getAvailability(val.drive)) {
        this.deleteFilter(val.drive);
      } else {
        this.filter.drive += val.drive + DIFF;
        this.allSelected.push(val.drive + DIFF);
      }
    },
    getCountry(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.country += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getCity(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.city += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getState(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.state += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getCondition(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.condition += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getManufacturer(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.manufacturer += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getCategory(val) {
      let isAvailable = this.allSelected.filter(
        (item) => item == val.value + "^^"
      );
      if (isAvailable.length) {
        this.allSelected = this.allSelected.filter(
          (item) => item != val.value + "^^"
        );
        this.filter.category = this.filter.category.replace(
          val.value + "^^",
          ""
        );
      } else {
        this.filter.category += val.value + "^^";
        this.allSelected.push(val.value + "^^");
      }

      // let parent = val.split("^^").filter(Boolean);
      // let isAvailable = this.allSelected.filter((item) =>
      //   parent.includes(item)
      // );
      // if (isAvailable.length) {
      //   this.filter.category = this.filter.category.replace(val, "");
      //   this.allSelected = this.allSelected.filter(
      //     (item) => !parent.includes(item)
      //   );
      // } else {
      //   this.filter.category += val;
      //   parent.forEach((item) => this.allSelected.push(item));
      // }
    },
    getListingType(val) {
      if (this.getAvailability(val.name)) {
        this.deleteFilter(val.name);
      } else {
        this.filter.listingType += val.name + DIFF;
        this.allSelected.push(val.name + DIFF);
      }
    },
    getAvailability(val) {
      return this.allSelected.includes(val + DIFF);
    },
    async getAllFilters() {
      try {
        const res = await customAxios.get("truck-filter-options/");
        this.allFilters = res.data;
      } catch (e) {
        alert(e);
      }
    },
  },
  created() {
    this.getAllFilters();
  },
  watch: {
    filter: {
      handler(val) {
        if (val.lastDays) {
          this.upperLimit = new Date();
        } else if (!val.lastDays) {
          this.upperLimit = null;
        }
        if ((val.date.min && val.date.max) || val.lastDays) {
          if (!this.allSelected.includes("date range")) {
            this.allSelected.push("date range");
          }
        }
        this.$store.dispatch("postfilterTrucks", val);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.trucks-container.show {
  height: 110vh;
  overflow-y: hidden;
}
h5 {
  color: #0057b7;
}
h6,
.circle-filter p {
  font-size: 0.85rem;
  font-weight: 501;
}
.header-wrap {
  background: #f6f6f6;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}
.header-wrap:last-child {
  border-bottom: 1px solid #444;
}
.filter-wrapper {
  border: 1px solid #ddd;
}
.filter-container p {
  color: rgb(110, 109, 109);
}
.search-input {
  padding: 10px 8px;
}
.search-input input,
button {
  height: 24px;
  border: 1px solid #ddd;
  font-size: 0.9rem;
}
.search-input input {
  flex: 3;
}
.search-input button {
  line-height: 12px;
  flex: 1;
}
button:hover:not(.filter-wrapper button) {
  background: #ddd;
}
input::placeholder,
.second-footer {
  font-size: 0.9rem;
}

@media screen and (min-width: 405px) {
  .trucks-container.show {
    height: 134vh;
  }
}
</style>
